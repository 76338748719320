import styled from 'styled-components';

export const Holder = styled.div`
  width: 100%;
  height: 500px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    width: 50%;
    font-size: 64px;
    font-weight: 600;
    color: var(--main-bg);

    text-align: center;
  }
  h2 {
    width: 50%;
    font-size: 32px;
    font-weight: 500;
    color: #000;

    text-align: center;

    padding-bottom: 15px;
  }
  a {
    border-radius: 30px;
    padding: 10px 30px;
    background-color: var(--main-bg);
    color: var(--main-color);
    font-weight: 600;
    text-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
  }

  @media (max-width: 768px) {
    & {
      h1 {
        font-size: 36px;
      }
      h2 {
        font-size: 24px;
      }
      h1,
      h2 {
        width: 90%;
      }
    }
  }
`;
