import React, { useEffect } from 'react';
import { navigate, PageProps } from 'gatsby';
import Layout from 'components/_layout';
import { Holder } from '../styles/pages/404';

const NotFound = (props: PageProps) => {
  const { location } = props;

  useEffect(() => {
    setTimeout(() => {
      navigate('/');
    }, 5000);
  }, []);

  return (
    <Layout pathname={location.pathname}>
      <Holder>
        <h1>404</h1>
        <h2>Essa página não existe</h2>
        <a href="/">Página Inicial</a>
      </Holder>
    </Layout>
  );
};

export default NotFound;
